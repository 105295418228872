import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from '../../../tools/i18n';
import ButtonV2, { ButtonV2Props } from '../../ButtonV2';
import Confetti from '../../Confetti';
import Modal from '../../Modal';
import { pictos } from '../../Picto';

interface ShareButtonProps extends Partial<ButtonV2Props> {
  id?: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({ id, ...props }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [href, setHref] = useState('');

  useEffect(() => {
    setHref(window.location.href);
  }, []);

  const url = encodeURIComponent(href);
  const items: { icon: keyof typeof pictos; title: string; link?: string }[] = [
    {
      icon: 'facebookV2',
      title: 'Facebook',
      link: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    },
    {
      icon: 'twitter',
      title: 'Twitter',
      link: `https://twitter.com/intent/tweet?url=${url}`,
    },
    {
      icon: 'whatsApp',
      title: 'WhatsApp',
      link: `https://api.whatsapp.com/send?text=${url}`,
    },
    {
      icon: 'envelopeV2',
      title: 'Email',
      link: `mailto:?body=${url})
      )}&subject=${document.title}`,
    },
  ];

  const handleShare = async () => {
    if (navigator?.share) {
      try {
        await navigator.share({
          title: document.title,
          url: window.location.href,
        });
      } catch (err) {
        console.error('Share failed:', err.message);
      }
      return;
    } else {
      setIsModalOpen(true);
    }
  };

  const handleCopy = async () => {
    if (!navigator.clipboard) return alert(t('ssrComponents.buttons.share.addBookmarkMobile'));

    await navigator.clipboard.writeText(window.location.href);
    setIsCopied(true);
  };

  return (
    <Fragment>
      <Modal
        title={t('ssrComponents.buttons.share.modal.title')}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="ShareModal"
      >
        <div className="ShareModal__container">
          <div className="ShareModal__container__items">
            {items.map((item, index) => (
              <ButtonV2
                id="share-items-button"
                className="ShareModal__container__items__item"
                key={index}
                text={item.title}
                variant="clear"
                leftIcon={item.icon}
                onClick={handleCopy}
                link={item.link}
              />
            ))}
          </div>
          <div className="ShareModal__container__copyContainer">
            <p>{t('ssrComponents.buttons.share.modal.orShareWithLink')}</p>
            <div className="ShareModal__container__copyContainer__input">
              <ButtonV2
              id="share-copy-button"
                className="ShareModal__container__copyContainer__input"
                text={href}
                variant="outline"
                rightIcon={isCopied ? 'copyCheckV2' : 'copyV2'}
                onClick={handleCopy}
              />
              {isCopied ? (
                <Confetti
                  confettiSource={{
                    x: window.innerWidth - 50,
                    y: window.innerHeight / 2,
                    w: 50,
                    h: 100,
                  }}
                  onConfettiComplete={() => setIsCopied(false)}
                />
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
      <ButtonV2
        id={id || "share-button"}
        text={t(`ssrComponents.buttons.share.text`)}
        variant="outline"
        leftIcon="shareV2"
        className="ShareButton"
        onClick={handleShare}
        {...props}
      />
    </Fragment>
  );
};

export default ShareButton;
